<template>
  <!-- 无服务商付款流程 -->
  <div class="noSupplierWaybillManagePage">
    <div class="facilityBox">
      <div class="title">运单信息</div>

      <!-- 表格信息 -->
      <el-table
        :data="tableData"
        v-loading="flag.loadingTable || flag.dialogLoading"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
      >
        <el-table-column
          fixed
          align="center"
          prop="index"
          label="序号"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="WaybillNumber"
          label="运单号"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="CarriageBillID"
          label="客户单号"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="Shipper_name"
          label="货主单位"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="DriverName"
          label="司机姓名"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="Phone"
          label=" 司机手机号码"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="DriverIDCard"
          label="
            司机身份证号码
          "
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              style="color: blue; cursor: pointer"
              @click="openDerverInfo(scope.row.DriverUserID)"
            >
              {{ scope.row.DriverIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="PayName"
          label="收款人姓名"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="Recy_payment_idcard"
          label="收款人手机号码"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="PayeeIDCard"
          label="收款人身份证号码"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              style="color: blue; cursor: pointer"
              @click="openDerverInfo(scope.row.PayeeUserID)"
            >
              {{ scope.row.PayeeIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="LeaderName"
          label="车队长姓名"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="LeaderPhone"
          label="车队长手机号码"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="LeaderIDCard"
          label="车队长身份证号码"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              style="color: blue; cursor: pointer"
              @click="openDerverInfo(scope.row.LeaderUserID)"
            >
              {{ scope.row.LeaderIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="LicensePlate"
          label="车牌"
          width="200"
        >
          <template slot-scope="scope">
            <div
              style="color: blue; cursor: pointer"
              @click="openVehicleInfo(scope.row)"
            >
              {{ scope.row.LicensePlate }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="Net_weight"
          label="装货净重(吨)"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="Product_name"
          label="货品名称"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="Product_unit"
          label="货品单位"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="Loading_time"
          label="装货时间"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="Submission_time"
          label="签收时间"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="Start_addrees"
          label="起始地"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              style="color: blue; cursor: pointer"
              @click="openMap(scope.row)"
            >
              {{ scope.row.Start_addrees }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="Arrivals"
          label="到达地"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              style="color: blue; cursor: pointer"
              @click="openMap(scope.row)"
            >
              {{ scope.row.Arrivals }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="Number_allocated_drivers"
          label="司机装货数量"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="Driver_transportation_price"
          label="司机运输单价（人民币）"
          width="200"
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.Driver_transportation_price | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="amount_count"
          label="运费"
          width="200"
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.amount_count | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column
          align="center"
          prop="Taxes"
          label="服务费/税费"
          width="200"
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.Taxes | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="TotalAmount"
          label="运单总金额"
          width="200"
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.TotalAmount | formatMoney }}
            </div>
          </template>
        </el-table-column> -->
        <el-table-column
          align="center"
          prop="Unloading_net_weight"
          label="卸货净重(吨)"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="SourceBillId"
          label="溯源运单号"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="RiskPath"
          label="风控路径"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop=""
          label="支付方式"
          width="200"
          show-overflow-tooltip
        ></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        style="margin-top: 16px"
        @current-change="handleCurrentChange1"
        :current-page.sync="pagination1.page"
        :page-size="pagination1.pagesize"
        layout="total, prev, pager, next, jumper"
        :total="pagination1.total"
      >
      </el-pagination>
      <!-- 右下角按钮组 -->
      <div style="text-align: center">
        <el-button
          type="primary"
          size="mini"
          @click="$router.push('/waybill/waybillManage/waybillRepository')"
          icon="el-icon-arrow-left"
          >返回</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :loading="flag.settlement"
          @click="(flag.allowDialog = true), getPaymentInfo()"
          >结算申请</el-button
        >
      </div>
    </div>
    <!-- 地图组件dialog -->
    <el-dialog width="800px" :visible.sync="flag.showMap" append-to-body>
      <!-- 地图组件 -->
      <TXmap
        style="height: 600px"
        v-if="flag.showMap"
        ref="map"
        :list="mapList"
      ></TXmap>
    </el-dialog>
    <!-- 车辆信息dialog -->
    <el-dialog
      width="1300px"
      :visible.sync="flag.showVehicleInfo"
      append-to-body
    >
      <VehicleInfo
        ref="vehicleInfo"
        v-if="flag.showVehicleInfo"
        :vehicleInfo="vehicleInfo"
      ></VehicleInfo>
    </el-dialog>
    <!-- 司机信息组件 -->
    <el-dialog
      width="1300px"
      :visible.sync="flag.showDriverInfo"
      append-to-body
    >
      <DriverInfo :driverInfo="driverInfo" />
    </el-dialog>

    <!-- 结算付款 -->
    <div class="allow">
      <el-dialog
        :visible.sync="flag.allowDialog"
        width="700px"
        top="13vh"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="付款详情"
      >
        <div class="line"></div>
        <div class="allow-info" style="display: flex">
          <p>
            <span class="info-title">电子账户余额：</span>
            <span class="info-content">{{
              accountInfo.ActBalance | formatMoney
            }}</span>
          </p>
          <p>
            <span class="info-title">专票/普票：</span>
            <span class="info-content">不开票</span>
          </p>
        </div>
        <div class="allow-info" style="display: flex">
          <p>
            <span class="info-title">账户可用金额：</span>
            <span class="info-content">{{
              (accountInfo.ActBalance - accountInfo.FrozeAmount) | formatMoney
            }}</span>
          </p>

          <p>
            <span class="info-title">在途资金：</span
            ><span class="info-content">{{
              accountInfo.FrozeAmount | formatMoney
            }}</span>
          </p>
        </div>
        <div class="allow-info" style="display: flex">
          <p>
            <span class="info-title">运费总金额：</span>
            <span class="info-content">{{ allTotalAmount | formatMoney }}</span>
          </p>
          <p>
            <span class="info-title">技术服务费率：</span>
            <span class="info-content">{{ serviceRate }} %</span>
          </p>
        </div>
        <div class="allow-info" style="display: flex">
          <p>
            <span class="info-title">服务费/税费：</span>
            <span class="info-content">{{ serviceCharge | formatMoney }}</span>
          </p>
          <p>
            <span class="info-title">支付方式：</span>
            <span class="info-content">线上支付(运费+服务费/税费 )</span>
          </p>
        </div>

        <div class="line"></div>
        <div class="btns">
          <div style="display: flex; align-items: center;">
            <span class="info-title">
              <span style="font-weight: bolder;">合计支付金额：</span>
              <span style="color: #ff8b17;"
                >{{ payTotalAmount | formatMoney }}
              </span></span
            >
          </div>
          <div>
            <el-button style="width: 94px;" @click="flag.allowDialog = false"
              >取消</el-button
            >
            <el-button
              type="primary"
              @click="comfirmPays"
              :loading="flag.comfirmPaying"
            >
              确认付款</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { getYMAccount } from "@/api/finance/bank";
import { getMapPath, getCarInfo, getDriverDetails } from "@/api/common/common";
import {
  GetNoSupplierList,
  PutPaymentWithout,
} from "@/api/waybill/waybillManage";
import TXmap from "@/components/commonCmpt/TXmap";
import VehicleInfo from "@/components/businessCmpt/vehicleInfo";
import DriverInfo from "@/components/businessCmpt/driverInfo";

export default {
  data() {
    return {
      ImportBatchID: "", // 待开票申请编号
      flag: {
        loadingTable: false, //是否正在加载表格
        showMap: false, //显示地图
        showVehicleInfo: false, //显示车辆信息
        showDriverInfo: false, //显示司机信息
        settlement: false, //结算申请中
        dialogLoading: false, //dialog弹窗是否是loading状态
        allowDialog: false, //结算dialog
        comfirmPaying: false, //确认付款中
      },
      //总运单
      pagination1: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      tableDataTotal: [], //表格数据总量
      mapList: [], //地图列表
      vehicleInfo: {}, //车辆信息
      driverInfo: {}, //司机信息
      accountInfo: {},
      allTotalAmount: 0, // 计算所有运单的总运费
      serviceRate: 0, // 技术服务费率
      serviceCharge: 0, // 服务费/税费
      payTotalAmount: 0, // 合计支付金额
    };
  },
  components: {
    TXmap,
    VehicleInfo,
    DriverInfo,
  },
  methods: {
    //获得业务类型和发票费率
    getWaybillBusinessType() {
      if (
        !this.getWaybillType.sceneId ||
        !this.getWaybillType.sceneType ||
        !this.getWaybillType.taskType ||
        !this.getWaybillType.rateType ||
        !this.getWaybillType.taskTypeId
      ) {
        this.$router.push("/waybill/waybillManage/businessChoose");
      }
      this.tableDataTotal = this.getWaybillType.waybillList;
      this.pagination1.total = this.tableDataTotal.length;

      // 获取技术服务费率， 运费总金额， 服务费
      GetNoSupplierList(12, this.ImportBatchID).then((res) => {
        let { Item1, Item2, Item3, Item4 } = res.data || {};
        this.serviceRate = Item3 || 0;
        this.allTotalAmount = Item1 || 0;
        this.serviceCharge = Item2 || 0;
        this.payTotalAmount = Item4 || 0;
        if (!Item3) this.$message.warning(`技术服务费率为 ${res.data}！`);
      });
    },
    //分页回调
    handleCurrentChange1(e) {
      this.pagination1.page = e;
    },
    //打开地图
    openMap(item) {
      this.flag.dialogLoading = true;
      let params = {
        wayBillId: item.WaybillNumber,
        searchWord: item.LicensePlate,
        stDate: item.Loading_time,
        edDate: item.Submission_time,
        coordType: 2,
      };
      getMapPath({ json: JSON.stringify(params) })
        .then((res) => {
          this.mapList = res.Track;
          this.flag.showMap = true;
        })
        .finally(() => {
          this.flag.dialogLoading = false;
        });
    },
    //打开车辆信息
    openVehicleInfo(item) {
      this.flag.dialogLoading = true;
      let params = {
        CarNumber: item.LicensePlate ? item.LicensePlate : item.CarNumber,
      };
      let data = {};
      getCarInfo(params)
        .then((res) => {
          data = Object.assign(
            {},
            res.data.vehicle_baseInfo,
            res.data.vehicle_confirmInfo
          );
          this.vehicleInfo = data;
          this.flag.showVehicleInfo = true;
        })
        .finally(() => {
          this.flag.dialogLoading = false;
        });
    },
    //查看司机信息
    openDerverInfo(UserID) {
      let data = {};
      getDriverDetails({ UserID: UserID })
        .then((res) => {
          data = Object.assign(
            {},
            res.data.driver_baseInfo,
            res.data.driver_confirmInfo
          );
          this.driverInfo = data;
          this.flag.showDriverInfo = true;
        })
        .finally(() => {
          this.flag.dialogLoading = false;
        });
    },

    //获取结算信息
    getPaymentInfo() {
      // 获取货主账户信息
      getYMAccount({
        userid: "",
      }).then((res) => {
        if (res.data) {
          this.accountInfo = res.data || {};
        }
      });
    },

    //确认付款
    comfirmPays() {
      this.$confirm("您是否确定对该运单进行付款？", "确认付款", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        center: true,
      })
        .then(() => {
          this.flag.comfirmPaying = true;
          const loading = this.$loading({
            lock: true,
            text: "付款中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          PutPaymentWithout("2", this.ImportBatchID)
            .then((res) => {
              if (res.data) {
                this.$message({
                  message: `付款成功, 结算单号：${res.data}`,
                  type: "success",
                  duration: 5000,
                });
                this.flag.allowDialog = false;
                this.$router.go(-1);
              }
            })
            .finally(() => {
              this.flag.comfirmPaying = false;
              loading.close();
            });
        })
        .catch(() => {
          loading.close();
        });
    },
  },
  computed: {
    ...mapGetters(["getWaybillType"]),

    //表格数据
    tableData() {
      return this.tableDataTotal.slice(
        this.pagination1.pagesize * (this.pagination1.page - 1),
        this.pagination1.pagesize * this.pagination1.page
      );
    },

    // 获取所有运单的总运费
    getTotalAmount() {
      this.allTotalAmount = 0;
      this.tableDataTotal.forEach((item) => {
        this.allTotalAmount += +item["amount_count"];
      });
      return this.allTotalAmount;
    },
  },
  created() {
    // 待开票申请编号
    if (this.$route.query.ImportBatchID) {
      this.ImportBatchID = this.$route.query.ImportBatchID;

      //获得业务类型
      this.getWaybillBusinessType();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/variable.scss";

/deep/.allow .el-dialog--center .el-dialog__body {
  padding: 0px 20px 10px 50px !important;
}
.title {
  color: #2081ff;
  position: relative;
  text-indent: 15px;
  font-size: 14px;
  border-bottom: 1px solid $borderGray;
  padding: 8px 0;
  margin-bottom: 20px;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 3px;
    height: 14px;
    border-radius: 5px;
    background: $primary;
  }
}

.allow-info {
  p {
    width: 50%;
    margin: 10px 0;
    text-align: left;
    display: flex;
    align-items: center;

    &:nth-child(1) {
      width: 50%;
    }

    .info-title {
      font-weight: bolder;
      width: 35%;
      text-align: right;
    }

    .info-content {
      color: #333;
    }

    .contentStyle {
      color: #ff8b17;
      font-weight: 600;
    }

    &:nth-child(2) {
      .info-title {
        width: 40%;
      }
    }
  }
}

.line {
  width: 100%;
  height: 1px;
  background-color: #f5f5f5;
  margin-top: 8px;

  &:nth-child(1) {
    margin-top: 0;
  }
}

.btns {
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin: 16px 0 10px 0;
}
</style>
